import React from 'react'
import {graphql} from 'gatsby'

import CenteredLayout from '../components/CenteredLayout/CenteredLayout'
import SEO from '../components/SEO/SEO'

import WhatWeDoGrid from '../components/WhatWeDoGrid/WhatWeDoGrid';

class WhatWeDoPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    return (
      <CenteredLayout location={this.props.location} title={siteTitle}>
        <SEO
          title="Was wir machen"
          keywords={[`whatwedo`, `services`, `responsibility`, `assesment`]}
        />
        <h1>
            Was wir machen - Prüfungen
        </h1>

          <WhatWeDoGrid />

      </CenteredLayout>
    )
  }
}

export default WhatWeDoPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
